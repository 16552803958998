import { Link } from "react-router-dom";
const Home = () => {
    return (<>
        {/* Start slider done */}
        <section className="slider-sec">
            <div className="slider-carousel owl-carousel owl-theme">
                <div className="slider-item">
                    <img
                        src="uploads/slider/1.webp"
                        alt="اقرار الضريبي"
                    />
                </div>
                <div className="slider-item">
                    <img
                        src="uploads/slider/2.webp"
                        alt="سلايدر المطاعم "
                    />
                </div>
                <div className="slider-item">
                    <img
                        src="uploads/slider/3.webp"
                        alt="سلايدر 2"
                    />
                </div>
                <div className="slider-item">
                    <img
                        src="uploads/slider/4.webp"
                        alt="سلايد  3"
                    />
                </div>
            </div>
        </section>
        {/* About done */}
        <section className="about-sec pad-50 mx-5 ">
            <div className="about-block row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center">
                    <img
                        className="image-topic2"
                        src="uploads/dashboardShow.jpg"
                        alt=" أنظمة تخطيط موارد الأعمال (ERP Systems)"
                    />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 align-self-center">
                    <div className="about-txt">
                        <h1> أنظمة تخطيط موارد الأعمال (ERP Systems)</h1>
                        <blockquote className="line-clamp">
                            أنظمة تخطيط موارد الأعمال (ERP Systems)تساعد هذه الأنظمة
                            المؤسسات على إدارة أنشطة الأعمال اليومية، مثل: المحاسبة
                            والمشتريات والمبيعات وإدارة المشاريع، وغيرها من الأنشطة.أصبحت
                            بيرد ج تخطيط موارد المؤسسة (ERP systems) من ال بيرد ج الضرورية
                            لتحسين أداء أي شركة، إذ تسهّل تلك ال بيرد ج من عملية إتمام المهام
                            بنجاح بفضل عمليات التكامل التي تتيحها بين المهام، ويعدّ برنامج
                            Birdsys أحد أهم  بيرد ج تخطيط الموارد، لما له من ميزات منافسة عن باقي
                            النظمما هو نظام تخطيط موارد الأعمال؟يمثل نظام تخطيط موارد
                            المؤسسة Enterprise Resource Planning) ERP) تطبيقًا برمجيًا يهدف
                            إلى أتمتة أعمال الشركة؛ من خلال الاعتماد على قاعدة بيانات مركزية
                            Central Database، تجمع بيانات جميع الأقسام في الشركة من ضمنها
                            بيانات سلاسل الإمداد، والمبيعات، والتسويق، والموارد ...
                        </blockquote>
                        <Link
                            className="btn btn-light custom-btn d-inline px-5"
                            to="/update-system/best-inn"
                        >
                            المزيد
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        {/* Department */}
        <section className="dept-sec gray-bg2 padt-50 mb-5">
            <div className="gredient-bg">
                <div className="container">
                    <div className="sec-title">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <h4>تطبيقات  بيرد </h4>
                                <p />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="dept-carousel">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="dept-block  ">
                                <Link to="/about-system">
                                    <div className="dept-icon">
                                        <img src="uploads/depart_system/info.png" alt="تعريف نظام بيرد " />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>تعريف نظام بيرد </h5>
                                        <blockquote>
                                            نظام تخطيط موارد المؤسسات (Birdsys) هو نظام برمجيات يُستخدم في
                                            إدارة العمليات والموارد داخل المؤسسات والشركات بشكل ...
                                        </blockquote>
                                        <button
                                            className="btn btn-light custom-btn d-inline"
                                        >
                                            المزيد
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="dept-block gray">
                                <Link to="/about-system">
                                    <div className="dept-icon">
                                        <img
                                            src="uploads/depart_system/Z9gOLEshC78Ylq2CPFskneRWwsNnp3OD.png"
                                            alt="بيرد نظام المشتريات"
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>بيرد نظام المشتريات</h5>
                                        <blockquote>
                                            المشتريات موديول   المشتريات في نظام بيرد ( بيرد ) يوفر العديد
                                            من المميزات، فهو يساعد على تنظيم عملية الشراء وإدارة
                                            الموردين...{" "}
                                        </blockquote>
                                        <button
                                            className="btn btn-light custom-btn d-inline"
                                        >
                                            المزيد
                                        </button>

                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="dept-block  ">
                                <Link to="/about-system">
                                    <div className="dept-icon">
                                        <img
                                            src="uploads/depart_system/gP75Ttv0LYLiahPtQwTNa4wpgO8yAI56.png"
                                            alt="بيرد لـنظام المبيعات  "
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>بيرد لـنظام المبيعات </h5>
                                        <blockquote>
                                            نظام المبيعات في نظام ( بيرد ) يتميز بالعديد من المميزات،فهو
                                            يساعد على إدارة عمليات البيع الشراء بشكل متكامل وفعال.من...{" "}
                                        </blockquote>
                                        <button
                                            className="btn btn-light custom-btn d-inline"
                                        >
                                            المزيد
                                        </button>
                                    </div>

                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="dept-block  gray  ">
                                <Link to="/about-system">

                                    <div className="dept-icon">
                                        <img
                                            src="uploads/depart_system/ZCbnU8-5wWKPIB897I-QVRVIFeQk-VgL.png"
                                            alt="بيرد لنظام المخازن والمستودعات "
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>بيرد لنظام المخازن والمستودعات </h5>
                                        <blockquote>
                                            المخازن نظام المخازن في نظام  ( بيرد ) يوفر العديد من
                                            المميزات الهامة لإدارة المخزون بشكل فعال. من بين هذه...{" "}
                                        </blockquote>

                                        <button
                                            className="btn btn-light custom-btn d-inline"
                                        >
                                            المزيد
                                        </button>

                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="dept-block  ">
                                <Link to="/about-system">
                                    <div className="dept-icon">
                                        <img
                                            src="uploads/depart_system/0HSdVehvDd-RtRw_S3LHQDeyNbsYF0WS.png"
                                            alt="بيرد نظام نقاط البيع POS"
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>بيرد نظام نقاط البيع POS</h5>
                                        <blockquote>
                                            نقاط البيع موديول نقاط البيع POS في نظام  (بيرد ) يوفر
                                            العديد من المزايا، فهو يسمح للمستخدمين بإدارة عمليات
                                            البيع...
                                        </blockquote>

                                        <button
                                            className="btn btn-light custom-btn d-inline"
                                        >
                                            المزيد
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="dept-block  gray  ">
                                <Link to="/about-system">
                                    <div className="dept-icon">
                                        <img
                                            src="uploads/depart_system/pB2nMYgp6-IddxIhXeCTkzPALn-n7WRC.png"
                                            alt="بيرد لنظام الحسابات "
                                        />
                                    </div>
                                    <div className="dept-desc">
                                        <h5>بيرد لنظام الحسابات </h5>
                                        <blockquote>
                                            الحسابات موديول الحسابات في نظام بيرد يتميز بالعديد من
                                            المميزات، فهو يساعد على إدارة الحسابات والمالية بشكل متكامل
                                            وفعال. يتضمن...
                                        </blockquote>

                                        <button
                                            className="btn btn-light custom-btn d-inline"
                                        >
                                            المزيد
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Department */}
        {/* Start Customers Section */}
        <section style={{ overflow: 'hidden', padding: '105px 0px 85px', display: 'block', boxSizing: 'border-box' }}>
            <div style={{ maxWidth: '1200px', width: '100%', paddingLeft: '15px', paddingRight: '15px', marginLeft: 'auto', marginRight: 'auto', boxSizing: 'border-box' }}>
                <div style={{ boxSizing: 'border-box' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-15px', marginRight: '-15px', boxSizing: 'border-box' }}>
                        <div data-wow-delay="300ms" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.3s', flex: '0 0 50%', maxWidth: '50%', animationName: 'fadeFromUp', position: 'relative', width: '100%', minHeight: '1px', paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}>
                            <div style={{ boxSizing: 'border-box' }}>
                                <div style={{ maxWidth: '520px', padding: '40px 50px 0px 0px', boxSizing: 'border-box' }}>
                                    <span style={{ color: 'rgb(149, 31, 179)', fontSize: '18px', fontWeight: 500, padding: '3px 30px', borderRadius: '8px', display: 'inline-block', backgroundColor: 'rgb(237, 224, 244)', boxSizing: 'border-box' }}>مميزاتنا الرائعة </span>
                                    <h2 style={{ fontSize: '40px', lineHeight: '50px', padding: '15px 0px', fontWeight: 700, color: 'rgb(1, 1, 1)', margin: '0px', fontFamily: 'pingarlt, Helvetica, Arial, sans-serif', marginBottom: '0px', marginTop: '0px', boxSizing: 'border-box' }}>مميزاتنا ستساعدك على<span style={{ fontWeight: 300, boxSizing: 'border-box' }}> تحسين عملك</span> </h2>
                                    <p style={{ marginBottom: '0px', paddingBottom: '43px', color: 'rgb(53, 53, 53)', fontSize: '17px', lineHeight: '26.996px', marginTop: '0px', boxSizing: 'border-box' }}>نيرابوس هو نظام إدارة الأعمال الذي يجمع بين جميع الأدوات التي تحتاجها لإدارة عملك بكفاءة وفعالية.</p>
                                    <div style={{ boxSizing: 'border-box' }}>
                                        <a href="https://erp.birdsys.net/business/register"  target="_blank" rel="noreferrer" style={{ color: 'rgb(33, 37, 41)', textDecoration: 'none solid rgb(33, 37, 41)', transition: 'all 0.3s ease-in-out 0s', padding: '10px 25px', position: 'relative', overflow: 'hidden', borderRadius: '50px', display: 'inline-block', fontSize: '15px', fontWeight: 700, border: '2px solid rgb(108, 43, 149)', backgroundColor: 'rgba(0, 0, 0, 0)', boxSizing: 'border-box' }}><i style={{ fontSize: '20px', marginLeft: '5px', background: 'rgba(0, 0, 0, 0) linear-gradient(45deg, rgb(107, 44, 148) 32%, rgb(252, 1, 253) 100%) repeat scroll 0% 0% / auto padding-box', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'rgba(0, 0, 0, 0)', fontFamily: '"font awesome 5 free"', fontWeight: 900, WebkitFontSmoothing: 'antialiased', display: 'inline-block', fontStyle: 'normal', fontVariant: 'normal', textRendering: 'auto', lineHeight: '20px', boxSizing: 'border-box' }}></i> ابدأ التجربة مجانا</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: '0 0 50%', maxWidth: '50%', position: 'relative', width: '100%', minHeight: '1px', paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box' }}>
                            <div style={{ paddingRight: '35px', boxSizing: 'border-box' }}>
                                <div data-wow-delay="0ms" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1.5s', animationDelay: '0s', animationName: 'fadeFromUp', marginBottom: '10px', padding: '22px 32px 20px', borderRadius: '10px', display: 'inline-block', transition: 'all 0.4s ease-in-out 0s', position: 'relative', boxSizing: 'border-box' }}>
                                    <div style={{ position: 'relative', backgroundColor: 'rgb(217, 243, 222)', width: '95px', float: 'right', height: '95px', borderRadius: '100%', lineHeight: '105px', marginLeft: '30px', textAlign: 'center', boxSizing: 'border-box' }}>
                                        <i className="fab fa-codepen" style={{ fontSize: "35px", border: "4px solid #71b664", color: "green", padding: "15px", borderRadius: "100%" }}></i>
                                    </div>
                                    <div style={{ maxWidth: '310px', display: 'inline-block', boxSizing: 'border-box' }}>
                                        <h3 style={{ color: 'rgb(1, 1, 1)', fontSize: '22px', fontWeight: 700, paddingBottom: '15px', margin: '0px', fontFamily: 'pingarlt, Helvetica, Arial, sans-serif', marginBottom: '0px', lineHeight: '26.4px', marginTop: '0px', boxSizing: 'border-box' }}>قابلة للتخصيص</h3>
                                        <p style={{ marginBottom: '0px', marginTop: '0px', boxSizing: 'border-box' }}>قم بتخصيص وتكييف نظامك ليناسب احتياجات عملك بدقة.</p>
                                    </div>
                                </div>
                                <div data-wow-delay="100ms" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.1s', animationName: 'fadeFromUp', marginBottom: '10px', padding: '22px 32px 20px', borderRadius: '10px', display: 'inline-block', transition: 'all 0.4s ease-in-out 0s', position: 'relative', boxSizing: 'border-box' }}>
                                    <div style={{ position: 'relative', backgroundColor: 'rgb(225, 225, 245)', width: '95px', float: 'right', height: '95px', borderRadius: '100%', lineHeight: '105px', marginLeft: '30px', textAlign: 'center', boxSizing: 'border-box' }}>
                                        <i className="fas fa-pen-square" style={{ fontSize: "35px", border: "4px solid #7964b6", color: "purple", padding: "15px", borderRadius: "100%" }}></i>
                                    </div>
                                    <div style={{ maxWidth: '310px', display: 'inline-block', boxSizing: 'border-box' }}>
                                        <h3 style={{ color: 'rgb(1, 1, 1)', fontSize: '22px', fontWeight: 700, paddingBottom: '15px', margin: '0px', fontFamily: 'pingarlt, Helvetica, Arial, sans-serif', marginBottom: '0px', lineHeight: '26.4px', marginTop: '0px', boxSizing: 'border-box' }}>سهل الاستخدام</h3>
                                        <p style={{ marginBottom: '0px', marginTop: '0px', boxSizing: 'border-box' }}>نظامنا سهل الاستخدام، مع أدوات بديهية تساعدك على إنجاز المهمة.</p>
                                    </div>
                                </div>
                                <div data-wow-delay="200ms" data-wow-duration="1500ms" style={{ visibility: 'visible', animationDuration: '1.5s', animationDelay: '0.2s', animationName: 'fadeFromUp', marginBottom: '10px', padding: '22px 32px 20px', borderRadius: '10px', display: 'inline-block', transition: 'all 0.4s ease-in-out 0s', position: 'relative', boxSizing: 'border-box' }}>
                                    <div style={{ position: 'relative', backgroundColor: 'rgb(235, 220, 244)', width: '95px', float: 'right', height: '95px', borderRadius: '100%', lineHeight: '105px', marginLeft: '30px', textAlign: 'center', boxSizing: 'border-box' }}>
                                        <i className="fas fa-headphones" style={{ fontSize: "35px", border: "4px solid #9c68bb", color: "#a53e81", padding: "15px", borderRadius: "100%" }}></i>
                                    </div>
                                    <div style={{ maxWidth: '310px', display: 'inline-block', boxSizing: 'border-box' }}>
                                        <h3 style={{ color: 'rgb(1, 1, 1)', fontSize: '22px', fontWeight: 700, paddingBottom: '15px', margin: '0px', fontFamily: 'pingarlt, Helvetica, Arial, sans-serif', marginBottom: '0px', lineHeight: '26.4px', marginTop: '0px', boxSizing: 'border-box' }}>دعم 24/7</h3>
                                        <p style={{ marginBottom: '0px', marginTop: '0px', boxSizing: 'border-box' }}>احصل على المساعدة عندما تحتاج إليها مع فريق دعم العملاء لدينا على مدار الساعة.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Start Contact Section */}
        <section className="contact-sec padt-50">
            <div className="container">
                <div className="sec-title">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h4>تواصل معنا</h4>
                            <p />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div
                    id="p0"
                    data-pjax-container=""
                    data-pjax-push-state=""
                    data-pjax-timeout={1000}
                >
                    <div className="row ">
                        <div className="col-md-6 col-sm-12 col-12">
                            <h2 className="mb-4">يمكنك التواصل معنا</h2>
                            <p></p>
                            <p>
                                عملاءنا الاعزاء يمكنكم التواصل معنا عن طريق البيانات التالية ،
                                ويسعدنا ويشرفنا استقبال اتصالكم مدار الساعة{" "}
                            </p>
                            <p />
                            <div className="ccontact-info">
                                <div className="media d-flex">
                                    <div className="contact-img-container ms-3 align-self-center flex-shrink-0">
                                        <img src="frontend/assets/image/icon-location.png" />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <p>
                                            المملكة العربية السعودية - جدة- طريق الملك عبدالله - عمارة الهدى
                                        </p>
                                    </div>
                                </div>
                                <div className="media d-flex">
                                    <div className="contact-img-container ms-3 align-self-center flex-shrink-0">
                                        <img src="frontend/assets/image/icon-phonecontact.png" />
                                    </div>
                                    <div className="media-body align-self-center flex-grow-1">
                                        <p>6514  53065  966 +</p>
                                    </div>
                                </div>
                                <div className="media d-flex">
                                    <div className="contact-img-container ms-3 align-self-center flex-shrink-0">
                                        <img src="frontend/assets/image/icon-mailcontact.png" />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <p>
                                            <a
                                                className="__cf_email__"
                                            >
                                                info@birdsys.net
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                            <form
                                id="w0"
                                className="login-frm"
                                action="https://Birdsys-soft.com/ar/site/contact"
                                method="post"
                                data-pjax=""
                            >
                                <input
                                    type="hidden"
                                    name="_csrf-frontend"
                                />
                                <div className="row"></div>
                                <div className="row m-md-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">الاسم</label>
                                            <div className="form-group field-contactus-name required">
                                                <input
                                                    type="text"
                                                    id="contactus-name"
                                                    className="form-control"
                                                    name="ContactUs[name]"
                                                    placeholder="الاسم"
                                                    aria-required="true"
                                                />
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">البريد الالكتروني</label>
                                            <div className="form-group field-contactus-email required">
                                                <input
                                                    type="text"
                                                    id="contactus-email"
                                                    className="form-control"
                                                    name="ContactUs[email]"
                                                    placeholder="البريد الالكتروني"
                                                    aria-required="true"
                                                />
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-md-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">الجوال</label>
                                            <div className="form-group field-contactus-mobile required">
                                                <input
                                                    type="text"
                                                    id="contactus-mobile"
                                                    className="form-control"
                                                    name="ContactUs[mobile]"
                                                    placeholder="الجوال"
                                                    aria-required="true"
                                                />
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">نوع التواصل</label>
                                            <div className="form-group field-contactus-contact_type_id">
                                                <select
                                                    id="contactus-contact_type_id"
                                                    className="form-control"
                                                    name="ContactUs[contact_type_id]"
                                                >
                                                    <option value="">نوع التواصل</option>
                                                    <option value={1}>إقتراح</option>
                                                    <option value={4}>شكوى </option>
                                                    <option value={5}>استشارات</option>
                                                    <option value={2}>طلب خدمة</option>
                                                    <option value={3}>طلب تسعيرة</option>
                                                </select>
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group m-md-3">
                                    <label className="form-label">رسالتك</label>
                                    <div className="form-group field-exampleFormControlTextarea1 required">
                                        <textarea
                                            id="exampleFormControlTextarea1"
                                            className="form-control text name"
                                            name="ContactUs[msg]"
                                            rows={5}
                                            placeholder="رسالتك"
                                            aria-required="true"
                                            defaultValue={""}
                                        />
                                        <p className="help-block help-block-error" />
                                    </div>
                                </div>
                                <div className="text-center mt-25">
                                    <button type="submit" className="btn btn-light custom-btn">
                                        ارسال
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Home;