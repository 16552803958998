import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            {/* Start Header */}
            <header className="header">
                <div className="top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 col-sm-12 col-12 align-self-center">
                                <ul className="nav contact-info-nav">
                                    <li className="nav-item">
                                        6514  53065  966 +
                                        <img src="frontend/assets/image/icon-phone.png" alt="call" />
                                    </li>
                                    <li className="nav-item">
                                        <a href="cdn-cgi/l/email-protection.html" className="__cf_email__" >info@birdsys.net</a>
                                        <img src="frontend/assets/image/icon-mail.png" alt="mail" />
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                                <nav className="navbar navbar-expand-lg justify-content-end">
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="frontend/assets/image/ar.png" alt="ico-lang" />
                                                &ensp;      العربيه  &ensp;
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <a className="dropdown-item" href="en.html?lang=en">
                                                        <img src="frontend/assets/image/en.png" alt="english" />إنجليزي
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="ar.html?lang=ar">
                                                        <img src="frontend/assets/image/ar.png" alt="Arabic" />عربي
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="separator">
                                            <div></div>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="uploads/depart_system/BirdSys-Accounting-System-V4_compressed.pdf" target="_blank" rel="noreferrer" download>
                                                <img src="frontend/assets/image/icon-pdf.png" alt="ico-pdf" />
                                                <span className="pdf-btn">تحميل البروفايل</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-11 offset-lg-1 col-md-12 col-sm-12 col-12 mb-1">
                                <nav className="navbar navbar-expand-lg">
                                    <div className="container-fluid">
                                        <NavLink className="navbar-brand" to="/">
                                            <img src="logo.png" alt="Birdsys" />
                                        </NavLink>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul id="w1" className="navbar-nav me-auto mb-2 mb-lg-0 nav">
                                                <li className="nav-item active">
                                                    <a className="nav-link" href="/">الصفحة الرئيسية</a>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="about-system">تعرف على النظام</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="/plan">الأسعار</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="/help">مركز المساعدة</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="/blog">المدونة</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="/faq">الأسئلة الشائعة</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="/contact-us">تواصل معنا</NavLink>
                                                </li>
                                            </ul>
                                            <div className="d-flex">
                                                <NavLink className="btn free-btn" to="/plan" >اشترك الان </NavLink>
                                                <a className="btn system-test-btn" href="https://erp.birdsys.net/business/register" target="_blank" rel="noreferrer">جرب النظام</a>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-header mobile-view">
                    <div className="container-fluid">
                        <div className="row no-gutters">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="logo-div">
                                    <NavLink to="#" className="menu-button">
                                        <i className="fas fa-bars"></i>
                                    </NavLink>
                                    <NavLink to="/" className="m-logo">
                                        <img src="logo.png" alt="Birdsys" />
                                    </NavLink>
                                    <ul className="nav m-main-menu">
                                        <li className="nav-item">
                                            <NavLink className="btn free-btn" to="/plan">اشترك الان </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <a className="btn system-test-btn" href="https://erp.birdsys.net/business/register" target="_blank" rel="noreferrer">جرب النظام</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mobile-menu-overlay"></div>
                    <div className="menu-mobile scroll scroll-wight-sidemenu">
                        <div className="show-menu-mobile">
                            <div className="header-menu-mobile">
                                <div className="logo-opacity-bg">
                                    <i className="fas fa-times close-menu"></i>
                                    <img src="logo.png" alt="Birdsys" className="" />
                                </div>
                                <ul className="nav nav-pills login-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="tel:0564723930">
                                            0564723930 <img src="frontend/assets/image/icon-phone.png" alt="call" />
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link">
                                            <span className="__cf_email__">info@birdsys.net</span>
                                            <img src="frontend/assets/image/icon-mail.png" alt="mail" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="body-menu-mobile">
                                <div className="fixed-menu">
                                    <ul id="w2" className="list-group list-group-flush nav">
                                        <li className="list-group-item active">
                                            <NavLink className="nav-link active" to="/site">الصفحة الرئيسية</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/about-system">تعرف على النظام</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/plan">الأسعار</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/partner">عملائنا</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/help">مركز المساعدة</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/blog">المدونة</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/faq">الأسئلة الشائعة</NavLink>
                                        </li>
                                        <li className="list-group-item">
                                            <NavLink className="nav-link" to="/contact-us">تواصل معنا</NavLink>
                                        </li>
                                    </ul>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item border-top">
                                            <NavLink className="nav-item mx-2" to="en.html?lang=en">
                                                <img src="frontend/assets/image/en.png" alt="ico-lang" style={{ maxWidth: '30px', margin: '5px' }} />
                                                إنجليزي
                                            </NavLink>
                                        </li>
                                        <div className="d-grid gap-2 mb-3">
                                            <NavLink className="btn free-btn" to="/plan" >اشترك الان </NavLink>
                                            <a className="btn system-test-btn" href="https://erp.birdsys.net/business/register" target="_blank" rel="noreferrer">جرب النظام</a>
                                            <NavLink className="btn system-test-btn mt-2" to="uploads/depart_system/BirdSys-Accounting-System-V4_compressed.pdf" target="_blank" rel="noreferrer">
                                                تحميل البروفايل
                                            </NavLink>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <a className='whatapp' href='https://api.whatsapp.com/send/?phone=966530656514&text&type=phone_number&app_absent=0'>
                <img src="uploads/whatsapp.png" width={70} alt="wstsap" />
            </a>
        </>
    );
};

export default Navbar;
