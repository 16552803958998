const contact = () => {
    return (<>
        {/* Start slider */}
        <section className="slider-sec slider-pages">
            <div className="slider-item">
                <img src="../uploads/slider/contact.png" alt="image" />
            </div>
        </section>
        {/* Start Breadcrumb */}
        <section className="breadcrumb-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="../index.html">الصفحة الرئيسية</a>
                                </li>
                                <span>&ensp;|&ensp; </span>

                                <li className="breadcrumb-item active">تواصل معنا</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* End Breadcrumb */}
        <section className="contact-sec padt-50">
            <div className="container">
                <div
                    id="p0"
                    data-pjax-container=""
                    data-pjax-push-state=""
                    data-pjax-timeout={1000}
                >
                    <div className="row ">
                        <div className="col-md-6 col-sm-12 col-12">
                            <h2 className="mb-4">يمكنك التواصل معنا</h2>
                            <p></p>
                            <p>
                                عملاءنا الاعزاء يمكنكم التواصل معنا عن طريق البيانات التالية ،
                                ويسعدنا ويشرفنا استقبال اتصالكم مدار الساعة{" "}
                            </p>
                            <p />
                            <div className="ccontact-info">
                                <div className="media d-flex">
                                    <div className="contact-img-container ms-3 align-self-center flex-shrink-0">
                                        <img src="frontend/assets/image/icon-location.png" alt="image" />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <p>
                                            المملكة العربية السعودية - جدة- طريق الملك عبدالله - عمارة الهدى
                                        </p>
                                    </div>
                                </div>
                                <div className="media d-flex">
                                    <div className="contact-img-container ms-3 align-self-center flex-shrink-0">
                                        <img src="frontend/assets/image/icon-phonecontact.png" alt="image" />
                                    </div>
                                    <div className="media-body align-self-center flex-grow-1">
                                        <p>6514  53065  966 +</p>
                                    </div>
                                </div>
                                <div className="media d-flex">
                                    <div className="contact-img-container ms-3 align-self-center flex-shrink-0">
                                        <img src="frontend/assets/image/icon-mailcontact.png" alt="image" />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <p>
                                            <a
                                                className="__cf_email__"
                                            >
                                                info@birdsys.net
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                            <form
                                id="w0"
                                className="login-frm"
                                action=""
                                method="post"
                                data-pjax=""
                            >
                                <input
                                    type="hidden"
                                    name="_csrf-frontend"
                                    defaultValue="XcDHlmWWuTujqODxraJXJgEkTkQeoXGw7jRQYg3Nk58QlqTUMfjyC-T-mpre5BxvNXUXMCaQPdjYbAUXd4rm9g=="
                                />
                                <div className="row"></div>
                                <div className="row m-md-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">الاسم</label>
                                            <div className="form-group field-contactus-name required">
                                                <input
                                                    type="text"
                                                    id="contactus-name"
                                                    className="form-control"
                                                    name="ContactUs[name]"
                                                    placeholder="الاسم"
                                                    aria-required="true"
                                                />
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">البريد الالكتروني</label>
                                            <div className="form-group field-contactus-email required">
                                                <input
                                                    type="text"
                                                    id="contactus-email"
                                                    className="form-control"
                                                    name="ContactUs[email]"
                                                    placeholder="البريد الالكتروني"
                                                    aria-required="true"
                                                />
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-md-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">الجوال</label>
                                            <div className="form-group field-contactus-mobile required">
                                                <input
                                                    type="text"
                                                    id="contactus-mobile"
                                                    className="form-control"
                                                    name="ContactUs[mobile]"
                                                    placeholder="الجوال"
                                                    aria-required="true"
                                                />
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="form-group">
                                            <label className="form-label">نوع التواصل</label>
                                            <div className="form-group field-contactus-contact_type_id">
                                                <select
                                                    id="contactus-contact_type_id"
                                                    className="form-control"
                                                    name="ContactUs[contact_type_id]"
                                                >
                                                    <option value="">نوع التواصل</option>
                                                    <option value={1}>إقتراح</option>
                                                    <option value={4}>شكوى </option>
                                                    <option value={5}>استشارات</option>
                                                    <option value={2}>طلب خدمة</option>
                                                    <option value={3}>طلب تسعيرة</option>
                                                </select>
                                                <p className="help-block help-block-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group m-md-3">
                                    <label className="form-label">رسالتك</label>
                                    <div className="form-group field-exampleFormControlTextarea1 required">
                                        <textarea
                                            id="exampleFormControlTextarea1"
                                            className="form-control text name"
                                            name="ContactUs[msg]"
                                            rows={5}
                                            placeholder="رسالتك"
                                            aria-required="true"
                                            defaultValue={""}
                                        />
                                        <p className="help-block help-block-error" />
                                    </div>
                                </div>
                                <div className="text-center mt-25">
                                    <button type="submit" className="btn btn-light custom-btn">
                                        ارسال
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default contact;